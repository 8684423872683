.list-contailer {
  margin: 0;
  padding: 0;
}

.item-list-container {
  display: grid;
  grid-template-areas: "icon text";
  grid-template-columns: 48px 1fr;
  grid-column-gap: 2rem;
  align-content: flex-start;
  margin: 2rem 0;
}

.item-list-container .icon {
  grid-area: icon;
}

.item-list-container .text {
  grid-area: text;
}

.item-list-container .text h3 {
  font-family: Roboto, sans;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  color: rgb(80, 80, 80);
}

.item-list-container .text p {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  color: rgb(100, 100, 100);
}

.list-w-title-container {
  margin: 0;
  padding: 0;
}

.list-w-title-container h2 {
  font-family: "PT Sans Narrow", sans;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 0 0 2rem 0;
  color: rgb(122, 101, 89);
}

.list-w-title-container p {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1.4rem;
  color: rgb(100, 100, 100);
}
