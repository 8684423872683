.head {
  grid-area: head;
}

.head h1 {
  font-family: "PT Sans Narrow", sans;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: left;
  text-shadow: 4px 4px 3px rgba(100, 100, 100, 0.25);
  color: rgb(122, 101, 89);
  margin: 0;
  padding: 1rem 0;
  padding-top: 0;
}

.head .type,
.head .descript {
  font-size: 0.8rem;
  font-family: Roboto, sans;
  font-weight: 400;
  color: rgb(100, 100, 100);
  padding: 0;
  margin: 0;
  text-align: left;
}

.head .descript {
  padding: 1rem 0 2rem;
  line-height: 1.2rem;
}

.image-head {
  grid-area: image;
  display: grid;
  background-color: transparent;
}

.image-head img {
  margin: auto;
  max-width: 420px;
}

.text-cnt {
  grid-area: text;
}

.text-cnt p {
  font-family: Roboto, sans;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  padding: 0.5rem 0;
  margin: 0;
  color: rgb(100, 100, 100);
  text-align: left;
}

.tags {
  padding: 2rem 0 1rem;
  margin: 0;
}

.tags a:link,
.tags a:visited {
  color: rgb(123, 143, 87);
  text-decoration: none;
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
}

.tags a:hover {
  color: rgb(67, 87, 163);
  text-decoration: none;
}

.other {
  grid-area: other;
}

.head-block {
  display: grid;
  grid-template-areas: "head image" "text image" "other image";
  grid-template-columns: 1fr 1fr;
  background: linear-gradient(
    to left,
    rgb(220, 220, 220) 0%,
    rgb(220, 220, 220) 60%,
    rgb(255, 255, 255) 60%,
    rgb(255, 255, 255) 100%
  );
}

@media (max-width: 499px) {
  .head-block {
    padding: 1rem;
    grid-template-areas: "head" "image" "text" "other";
    grid-template-columns: 1fr;
    background: rgb(255, 255, 255);
  }
  .image-head {
    padding: 2rem;
    background-color: rgb(220, 220, 220);
  }
  .image-head img {
    margin: auto;
    max-width: calc(100vw - 6rem);
  }
  .tags {
    margin: 0 auto;
    max-width: max-content;
    box-sizing: border-box;
  }
  .text-cnt {
    padding-top: 2rem;
  }
}
