.App {
  padding: 0;
}
@font-face {
  font-family: "Futura-Normal";
  src: url("./futura.woff") format("woff");
}

/* Roboto regular */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

/* Roboto italic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/Roboto-Italic.ttf") format("truetype");
}

/* Roboto bold */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

/* Roboto medium */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
}

/* Roboto bold italic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
}

/* Roboto Medium italic */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/Roboto-MediumItalic.ttf") format("truetype");
}

/* Alice regular */
@font-face {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Alice-Regular.ttf") format("truetype");
}

/* Alegreya Sans regular */
@font-face {
  font-family: "Alegreya Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/AlegreyaSans-Regular.ttf") format("truetype");
}

/* Alegreya Sans italic */
@font-face {
  font-family: "Alegreya Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/AlegreyaSans-Italic.ttf") format("truetype");
}

/* Alegreya Sans Medium */
@font-face {
  font-family: "Alegreya Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/AlegreyaSans-Medium.ttf") format("truetype");
}

/* Alegreya Sans Bold */
@font-face {
  font-family: "Alegreya Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/AlegreyaSans-Bold.ttf") format("truetype");
}

/* Alegreya Sans Bold Italic */
@font-face {
  font-family: "Alegreya Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/AlegreyaSans-BoldItalic.ttf") format("truetype");
}

/* Alegreya Sans Medium italic */
@font-face {
  font-family: "Alegreya Sans";
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/AlegreyaSans-MediumItalic.ttf") format("truetype");
}

/* PT Sans Narrow */
@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/PTSansNarrow-Regular.ttf") format("truetype");
}

/* PT Sans Narrow Bold */
@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 700;
  src: local("PT Sans Narrow Bold"),
    url("./fonts/PTSansNarrow-Bold.ttf") format("truetype");
}

/* PlayfairDisplay regular */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/PlayfairDisplay-Regular.ttf") format("truetype");
}

/* PlayfairDisplay medium */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/PlayfairDisplay-Medium.ttf") format("truetype");
}

/* PlayfairDisplay italic */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/PlayfairDisplay-Italic.ttf") format("truetype");
}

/* PlayfairDisplay bold */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}

tb-notification-button {
  width: 30%;
  float: left;
  display: block;
  padding: 1.5%;
}

tb-notification-button path {
  fill: transparent;
}

/* tb-notification-button path:last-child {
  fill: #7b8f57;
} */

#tb-notification-button-0 path:last-child {
  fill: rgb(66, 103, 178);
}

#tb-notification-button-1 path:last-child {
  fill: rgb(53, 172, 225);
}

#tb-notification-button-2 path:last-child {
  fill: rgb(74, 118, 168);
}

/* body *:not(.fa) {
  font-family: "Roboto" /*, "Futura-Normal", "Helvetica" ;
} */
body {
  margin: 0;
  padding: 0;
  color: rgb(80, 80, 80);
  /* overflow-x: hidden; */
  font-family: "Roboto" /*, "Futura-Normal", "Helvetica" */;
}
h2 {
  font-family: "PT Sans Narrow";
  color: rgb(122, 101, 89);
}
a:link {
  text-decoration: none;
  color: rgb(123, 143, 87);
  font-family: inherit;
}
a:visited {
  text-decoration: none;
  color: rgb(123, 143, 87);
  font-family: inherit;
}
a:hover {
  text-decoration: none;
  color: rgb(100, 123, 67);
  font-family: inherit;
}
b {
  font-family: inherit;
}

.pixel {
  height: 1px;
}
