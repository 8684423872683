.tile-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
}

.tile-item {
  max-width: 100%;
}

.tile-image {
  overflow: hidden;
  text-align: center;
}

.tile-image img {
  max-width: 100%;
  height: auto;
}

.tile-item h3 {
  font-family: Roboto, sans;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgb(80, 80, 80);
  text-align: left;
  line-height: 1.2rem;
  margin: 0;
  margin-top: 1.6rem;
  margin-bottom: 1.4rem;
}

.tile-item p {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.5rem;
}

.tile-item .tile-button {
  background-color: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  border: 1px solid rgb(100, 100, 100);
  text-align: center;
  padding: 0.5rem 1rem;
  max-width: max-content;
  transition: color 0.5s, background-color 0.5s;
  max-height: max-content;
  box-sizing: border-box;
}

.tile-item .tile-button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition: color 0.5s, background-color 0.5s;
  cursor: pointer;
}

.tile-button a:link,
.tile-button a:visited {
  color: rgb(100, 100, 100);
}

.tile-button a:hover {
  color: rgb(255, 255, 255);
}

@media (max-width: 900px) {
  .tile-container {
    grid-template-columns: 1fr;
  }
}
