.image-cnt {
  background-color: rgb(100, 100, 100);
  background-position: center;
  background-size: 200%;
  background-blend-mode: multiply;
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: grid;
}

.image-cnt img.vertical {
  height: 200px;
  width: auto;
  justify-self: center;
}

.image-cnt img.horizontal {
  width: 200px;
  height: auto;
  align-self: center;
}

.tile-cnt {
  width: 200px;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 12px 2px rgba(100, 100, 100, 0.1);
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
}

.tile-cnt h4 {
  margin: 0;
  text-align: left;
  line-height: 1.4rem;
  padding: 1.2rem;
  font-family: Roboto, sans;
  font-size: 1.1rem;
  font-weight: 700;
  color: rgb(80, 80, 80);
}

.tile-cnt .p,
.tile-cnt p {
  margin: 0;
  text-align: left;
  line-height: 1.4rem;
  padding: 0.5rem 1.2rem 1rem 1.2rem;
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
}

.p p {
  padding: 0.5rem 0 1rem 0;
}

.tile-cnt li.p {
  padding: 0.25rem 0;
}

.grid4x {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  justify-items: center;
}

.grid3x {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  justify-items: center;
}

.grid3x .image-cnt {
  background-color: rgb(160, 160, 160);
  background-position: center;
  background-size: 120%;
  background-blend-mode: multiply;
  width: 280px;
  height: 280px;
  overflow: hidden;
  display: grid;
}

.grid3x .image-cnt.x133 {
  width: 280px;
  height: calc(280px / 1.333);
}

.grid3x .image-cnt img.vertical {
  height: 280px;
  width: auto;
  justify-self: center;
}

.grid3x .image-cnt.x133 img.vertical {
  height: calc(280px / 1.333);
  width: auto;
  justify-self: center;
}

.grid3x .image-cnt img.horizontal,
.grid3x .image-cnt.x133 img.horizontal {
  width: 280px;
  height: auto;
  align-self: center;
}

.grid3x .tile-cnt {
  width: 280px;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 12px 2px rgba(100, 100, 100, 0.1);
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
}

@media (max-width: 699px) {
  .grid4x {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: auto;
  }
  .grid3x {
    grid-template-columns: 1fr 1fr;
    grid-gap: auto;
  }
}

@media (max-width: 499px) {
  .grid4x {
    grid-template-columns: 1fr 1fr;
    grid-gap: auto;
  }
  .grid3x {
    grid-template-columns: 1fr;
    grid-gap: auto;
  }
  .grid3x .tile-cnt {
    width: 80vw;
  }
  .grid3x .image-cnt {
    width: 80vw;
    height: 80vw;
  }
  .grid3x .image-cnt.x133 {
    width: 80vw;
    height: calc(80vw / 1.333);
  }
  .grid3x .image-cnt img.vertical {
    height: 80vw;
    width: auto;
  }
  .grid3x .image-cnt img.horizontal {
    width: 80vw;
    height: auto;
  }
}

@media (max-width: 440px) {
  .grid4x {
    grid-template-columns: 1fr;
    grid-gap: auto;
  }
  .tile-cnt {
    width: 80vw;
  }
  .image-cnt {
    width: 80vw;
    height: 80vw;
  }
  .image-cnt img.vertical {
    height: 80vw;
    width: auto;
  }
  .image-cnt img.horizontal {
    width: 80vw;
    height: auto;
  }
}
