.person-container {
  display: grid;
  grid-template-areas: "text photo";
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 2rem;
}

.person-text {
  grid-area: text;
  padding: 1rem;
}

.review-block {
  padding: 1rem;
}

.bot-border {
  border-bottom: 1px solid rgb(240, 240, 240);
}

.person-text p,
.review-block p {
  font-family: Roboto;
  text-align: justify;
  color: rgb(100, 100, 100);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.person-text li {
  font-family: Roboto;
  text-align: justify;
  color: rgb(100, 100, 100);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.person-text a:link,
.person-text a:visited {
  font-family: Roboto;
  color: rgb(50, 50, 50);
  text-decoration: none;
}

.person-text a:hover {
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: underline;
}

.person-h2 {
  font-family: Roboto;
  text-align: center;
  color: rgb(80, 80, 80);
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  margin-top: 4rem;
}

.person-text h3,
.review-block h3 {
  font-family: Roboto;
  text-align: left;
  color: rgb(80, 80, 80);
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 700;
}

.person-text h4,
.review-block h4 {
  font-family: Roboto;
  text-align: left;
  color: rgb(100, 100, 100);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 700;
}

.person-all-link {
  text-align: center;
  font-weight: 700;
  transition: background-color 0.5s;
  color: rgb(255, 255, 255);
  background-color: rgb(123, 143, 87);
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem auto;
  box-sizing: border-box;
  max-width: max-content;
}

.person-all-link:hover {
  background-color: rgb(100, 120, 67);
}

/* .person-all-link a:link {
  text-decoration: none;
  color: inherit;
}

.person-all-link a:hover {
  text-decoration: none;
  color: inherit;
} */

.person-photo {
  grid-area: photo;
  padding: 1rem;
  text-align: center;
}

.person-photo img {
  max-width: 100%;
  margin: 0 auto;
  position: sticky;
  top: 1px;
}

.slider-descript {
  text-align: left;
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 1rem 0;
  color: rgb(120, 120, 120);
}

.slider-box {
  margin: 1rem auto;
  max-width: 900px;
  transition: all 0.5s;
  overflow: hidden;
}

.spoller-button {
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  margin: 1rem auto;
  max-width: max-content;
  border: 1px solid;
  border-color: rgb(123, 143, 87);
  border-radius: 4px;
  transition: color 0.5s;
  color: rgb(123, 143, 87);
}

.spoller-button:hover {
  color: rgb(100, 120, 67);
  border-color: rgb(100, 120, 67);
  transition: color 0.5s, border-color 0.5s;
  cursor: pointer;
}

.photosession-box {
  display: grid;
  grid-template-areas: "photo descript";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
}

.photosession-slider {
  grid-area: photo;
  width: 500px;
  height: 500px;
}

.photosession-descript {
  grid-area: descript;
  text-align: left;
}

.photosession-image {
  max-height: 350px;
  max-width: 100%;
}

@media (max-width: 959px) {
  .person-container {
    grid-template-areas: "photo" "text";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
  }

  .person-text p {
    text-align: left;
  }

  .person-text li {
    text-align: left;
  }

  .photosession-box {
    grid-template-areas: "photo" "descript";
    grid-row-gap: 1rem;
  }

  .photosession-slider {
    width: 85vw;
    height: 85vw;
    margin: 0 auto;
  }

  .photosession-image {
    max-height: calc(85vw - 60px);
  }
}
