.element-psevdolist {
  margin: 3rem 0;
  box-sizing: border-box;
}
.head-psevdolist {
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  font-weight: 700;
  text-align: left;
}
.head-psevdolist:hover {
  color: rgb(50, 50, 50);
  cursor: pointer;
}
.paragraph-psevdolist {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  text-align: left;
  padding: 0;
  margin: 0;
}
.head-level1 {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(122, 101, 89);
  text-align: center;
  padding: 0;
  margin: 2rem 0;
}
.select-box {
  padding: 1rem 0;
}
.select-box select {
  color: rgb(100, 100, 100);
  font-family: Roboto, sans;
  font-size: 1rem;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 0.5rem;
  background-color: rgb(255, 255, 255);
  margin: 0 1rem 0 0;
  width: 200px;
  display: inline-block;
  vertical-align: top;
}
.select-box select:focus {
  outline: 0;
  border: 1px solid rgb(200, 200, 200);
}
.select-box select[multiple] option {
  padding: 2px;
}
.button-select {
  border: 1px solid rgb(100, 100, 100);
  background-color: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  padding: 0.5rem;
  text-align: center;
  width: 200px;
  transition: 0.5 color, 0.5 background-color;
  box-sizing: border-box;
}
.button-select:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  transition: 0.5 color, 0.5 background-color;
  cursor: pointer;
}
.select-component-container {
  width: 200px;
  max-height: 0;
  border: 0px solid rgb(220, 220, 220);
  border-radius: 4px;
  transition: 0.5s all;
  overflow: hidden;
  box-sizing: border-box;
}
.open {
  max-height: 300px;
  transition: 0.5s all;
  border: 1px solid rgb(220, 220, 220);
}
.select-component-container ul {
  margin: 0;
  padding: 0;
  width: 200px;
}
.select-component-container li {
  padding: 2px 4px;
  font-family: Roboto, sans;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  background-color: rgb(255, 255, 255);
  list-style: none;
}
.select-component-container li.selected {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.select-component-container li:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.selects {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  grid-template-areas: "title theme duration";
  max-width: 650px;
}
.selects-title {
  grid-area: title;
}
.selects-theme {
  grid-area: theme;
}
.selects-duration {
  grid-area: duration;
}
.title-button {
  display: grid;
  grid-template-columns: 1fr 1rem;
  grid-template-areas: "text icon";
  justify-content: space-between;
}
.title-text {
  grid-area: text;
  text-align: center;
}
.title-icon {
  grid-area: icon;
}
.expanded-close {
  display: inline-block;
  transform: rotate(315deg);
  transition: 0.5s transform;
  font-size: 0.7rem;
}
.expanded-open {
  display: inline-block;
  font-size: 0.7rem;
  transform: rotate(135deg);
  transition: 0.5s transform;
}
@media (max-width: 599px) {
  .selects {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "title" "theme" "duration";
    max-width: 90vw;
    margin: 0 auto;
  }
  .selects-title {
    margin: 1rem;
  }
  .selects-theme {
    margin: 0 1rem;
  }
  .selects-duration {
    margin: 1rem 1rem 0 1rem;
  }
}
