.image_in_slider {
  margin: 0 auto;
  transition: opacity 0.2s;
}

@media (max-width: 959px) {
  .image_in_slider {
    margin: 0 auto;
    max-width: 100%;
  }
}

.slider__box {
  max-width: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 1rem;
}

.slider__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.informer__control {
  display: inline-block;
  margin: 0 1rem;
}

.button__control:hover {
  cursor: pointer;
  filter: contrast(250%);
}
