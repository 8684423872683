.title-text-container {
  margin: 0;
  padding: 0;
}
.title-text-container h2 {
  font-family: "PT Sans Narrow";
  font-size: 2rem;
  color: rgb(122, 101, 89);
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  line-height: 2rem;
  padding: 0;
  font-weight: 700;
}
.title-text-container .button {
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(100, 100, 100);
  padding: 0.5rem 1rem;
  max-width: max-content;
  max-height: max-content;
  transition: color 0.5s, background-color 0.5s;
}
.title-text-container .button:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  transition: color 0.5s, background-color 0.5s;
}
.title-text-after-title {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(80, 80, 80);
  text-align: center;
  margin: 0;
  line-height: 1.4rem;
  padding: 0;
}
.title-text-container .content {
  margin-top: 6rem;
  max-width: 100%;
}
