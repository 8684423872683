.review-shess-image-box {
  background-color: rgb(240, 240, 240);
  background-image: linear-gradient(
      45deg,
      rgb(230, 230, 230) 25%,
      transparent 25%,
      transparent 75%,
      rgb(230, 230, 230) 75%,
      rgb(230, 230, 230)
    ),
    linear-gradient(
      45deg,
      rgb(230, 230, 230) 25%,
      transparent 25%,
      transparent 75%,
      rgb(230, 230, 230) 75%,
      rgb(230, 230, 230)
    );
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
  height: 100%;
  display: grid;
  align-items: center;
  /* max-width: 100%; */
}

img.review-image {
  max-width: 100%;
  vertical-align: middle;
  margin: auto;
  object-position: 50% 50%;
}

.reviews-tiles h3 {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  color: rgb(80, 80, 80);
  margin: 0;
  margin-bottom: 2rem;
}
