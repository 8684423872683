.title-spoler {
  display: grid;
  grid-template-columns: 1fr 20px;
  cursor: pointer;
}
.title-spoler-text,
.open-close-spoler {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(80, 80, 80);
  text-align: left;
  line-height: 1.4rem;
}
.title-spoler-text {
  padding: 1rem 0;
}
.open-close-spoler {
  padding: 0 0.5rem;
  font-size: 1.5rem;
}
.spoler-open {
  transform: rotate(45deg);
  transition: 0.5s transform, 0.5s color;
  color: rgb(200, 200, 200);
}
.spoler-close {
  transform: rotate(0deg);
  transition: 0.5s transform, 0.5s color;
  color: rgb(100, 100, 100);
}
.spoler-text-open {
  max-height: 1000px;
  transition: 0.5s max-height;
  padding: 1rem 0;
}
.spoler-text-close {
  max-height: 0px;
  transition: 0.5s max-height;
  overflow: hidden;
}
