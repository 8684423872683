.first-last-line {
  height: 1px;
  width: 100%;
  background: transparent;
}

.middle-line {
  height: 1px;
  width: 100%;
  background: rgb(100, 100, 100);
}

.digit-in-circle {
  font-size: 1rem;
  font-family: Roboto;
  padding: 0.5rem 0;
  background: rgb(100, 100, 100);
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 50%;
  width: 2.2rem;
  margin: 0 auto;
}

.mobile-line {
  height: 100%;
  margin: 0 auto;
  background: rgb(100, 100, 100);
}

h3.h3-sequence {
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  font-weight: 700;
  text-align: center;
}

.text-sequence {
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  font-weight: 400;
  padding: 1rem;
  text-align: justify;
}

@media (max-width: 599px) {
  .text-sequence {
    text-align: left;
  }
}
