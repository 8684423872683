.title-w-icon {
  max-width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1.2rem max-content;
  grid-template-areas: "icon title";
  align-content: center;
  justify-content: first baseline;
  grid-column-gap: 1rem;
}

.title-w-icon .icon {
  padding: 0;
  margin: 0;
  grid-area: icon;
}

.title-w-icon .title {
  font-family: Roboto, sans;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2rem;
  color: rgb(80, 80, 80);
  width: max-content;
  grid-area: title;
}
