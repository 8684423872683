.block-bkg-container {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(80, 20, 0, 0.8);
  background-blend-mode: multiply;
  height: 85vh;
  display: flex;
}

.bkg-content {
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 6rem 6rem;
  align-self: center;
}

.bkg-content h2 {
  font-family: "PT Sans Narrow", sans;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1.6rem;
  padding: 0;
  margin: 0;
  color: rgb(255, 255, 255);
}

.bkg-content .content-line {
  height: 1px;
  width: 120px;
  background-color: rgb(255, 255, 255);
  margin: 2rem auto;
}

.bkg-content p {
  /* text-align: center; */
  font-family: Roboto, sans;
  font-size: 1rem;
  line-height: 2rem;
}

@media (max-width: 599px) {
  .bkg-content {
    padding: 3rem 2rem;
  }
  .block-bkg-container {
    height: max-content;
  }
}
