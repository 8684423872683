.number-icon {
  background-color: rgb(122, 101, 89);
  border-radius: 50%;
  padding: 0;
  line-height: 48px;
  width: 48px;
  height: 48px;
  font-family: Roboto, sans;
  font-size: 20px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  text-align: center;
}

.grid-6x2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
}

@media (max-width: 599px) {
  .grid-6x2 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
}
