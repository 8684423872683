.box-infographiqa {
  position: relative;
}

.mt {
  padding-top: 1rem;
  font-size: 1.2rem;
}

.fsmall {
  font-size: 0.8rem;
}

.pseudo-graphica {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.text-hover {
  position: absolute;
  top: 0;
  left: 25%;
  box-sizing: border-box;
}

.shess-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.shess-item-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.shess-item-2 {
  grid-column-start: 2;
  grid-column-end: 4;
}

.button-free-lessons {
  max-width: 100%;
  background: rgb(252, 168, 48);
  padding: 2rem 4rem;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  margin: 0 auto;
}

.button-text {
  color: rgb(122, 101, 89);
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s color;
}

.button-text:hover {
  color: rgb(255, 255, 255);
  transition: 0.5s color;
}

.box-news {
  padding-right: 2rem;
}

@media (max-width: 590px) {
  .box-news {
    padding-right: 0;
  }
}
