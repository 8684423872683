.infographica-box {
  height: max-content;
}

.pseudo-graphica {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.no-shess {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  grid-column-gap: 2rem;
  align-items: center;
}

.pseudo-graphica-no-shess {
  padding: 1rem;
  padding-top: 0;
}

.text-no-shess {
  padding: 1rem;
}

.text-hover {
  position: relative;
  top: -4.5rem;
  z-index: 10;
}

.text-infographica-f-p-m {
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1rem;
  text-align: justify;
  margin: 0;
  line-height: 1.4rem;
  color: rgb(100, 100, 100);
}

.shess-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.shess-item-no-shess {
  grid-column-start: 2;
  grid-column-end: 5;
}

.shess-item-no-shess-large {
  grid-column-start: 1;
  grid-column-end: 5;
}

.shess-item-1 {
  grid-column-start: 2;
  grid-column-end: 4;
  align-self: center;
}

.shess-item-2 {
  grid-column-start: 3;
  grid-column-end: 5;
  align-self: center;
}

.digital {
  font-family: "Playfair Display";
  color: rgb(252, 168, 48); /* rgb(210, 210, 210); */
  font-size: 6rem;
  font-weight: 900;
  text-align: center;
  line-height: 2.7rem;
}

.digital-word {
  font-size: 1.4rem;
  line-height: 1rem;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 959px) {
  .shess-box {
    display: grid;
    grid-template-columns: 1fr;
  }

  .shess-item-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 1rem;
  }

  .shess-item-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 1rem;
  }
}
