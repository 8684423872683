.head-page {
  margin: 0 auto;
  text-align: center;
  max-width: 1000px;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.descript-header {
  text-align: right;
}

.hs {
  font-family: "PT Sans Narrow", sans;
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  line-height: 2.4rem;
}

.lh-big {
  line-height: 1.6rem;
}

a.tags:link,
a.tags:visited {
  color: rgb(123, 143, 87);
  text-decoration: none;
}

a.tags:hover {
  color: rgb(60, 100, 150);
  text-decoration: none;
}

.imagemaker-stylist-tags a.red-link:link,
.imagemaker-stylist-tags a.red-link:visited {
  color: rgb(160, 0, 0);
  text-decoration: none;
}

.imagemaker-stylist-tags a.red-link:hover {
  color: rgb(200, 0, 0);
  text-decoration: none;
}

h4.tesises {
  font-size: 1rem;
  font-weight: 700;
  color: rgb(80, 80, 80);
  text-align: left;
  margin: 1rem 0;
}

p.tesises {
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  text-align: left;
  line-height: 1.4rem;
  margin: 0;
  padding: 0;
}

.imagemaker-stylist-header {
  box-sizing: border-box;
  padding: 2rem 0 0 0;
  margin: 0;
}
.imagemaker-stylist-header p,
.imagemaker-stylist-header a {
  font-family: Roboto, sans;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgb(100, 100, 100);
  text-align: left;
  padding: 0.2rem 0;
  margin: 0;
}
.imagemaker-stylist-header h3 {
  font-family: "PT Sans Narrow", sans;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  color: rgb(122, 101, 89);
  text-shadow: 2px 2px 4px rgba(100, 100, 100, 0.25);
  text-align: left;
  margin: 0;
  padding: 2rem 0 1.4rem;
}

.imagemaker-stylist-tags {
  padding-top: 2rem;
}

.imagemaker-stylist-tags a:link,
.imagemaker-stylist-tags a:visited {
  text-decoration: none;
  color: rgb(123, 143, 87);
}

.imagemaker-stylist-tags a:hover {
  text-decoration: none;
  color: rgb(67, 87, 163);
}

.imagemaker-stylist-head-form {
  background-color: white;
  margin: 2rem auto;
  box-sizing: border-box;
  padding: 2rem;
  max-width: 400px;
}

.imagemaker-stylist-head-logo {
  background-color: rgb(25, 25, 25);
  margin: 2rem auto;
  box-sizing: border-box;
  padding: 2rem;
  padding-top: 3rem;
  max-width: 360px;
}

.imagemaker-stylist-head-logo img {
  max-width: 180px;
  height: auto;
}

.imagemaker-stylist-head-logo h3 {
  font-family: "PT Sans Narrow", sans;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: rgb(255, 255, 255);
}

.imagemaker-stylist-head-form h3 {
  font-family: Roboto, sans;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgb(80, 80, 80);
  text-align: center;
  margin: 0;
  padding: 0;
  padding-bottom: 1.4rem;
}

@media (max-width: 959px) {
  .head-page {
    margin-bottom: 0;
    background: url("../../../public/img/imagemaker-stylist/bkg.jpg");
    background-size: cover;
    color: rgb(0, 0, 0);
  }

  .descript-header {
    text-align: left;
  }

  .imagemaker-stylist-header {
    padding: 0;
  }

  .imagemaker-stylist-head-form {
    max-width: calc(100vw - 4rem);
  }

  .imagemaker-stylist-tags {
    padding-bottom: 4rem;
  }

  h4.tesises {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  p.tesises {
    padding: 0 1rem;
  }

  .icon-tesisses {
    padding-left: 1rem;
  }
}
