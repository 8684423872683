.image-container {
  text-align: center;
}

.image-container img {
  max-width: 400px;
}

@media (max-width: 900px) {
  .image-container img {
    max-width: 85vw;
  }
}
