.tile-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
  align-items: stretch;
  align-content: flex-start;
  max-width: 800px;
  margin: 0 auto;
}
.tile-image-item {
  max-width: max-content;
}
.tile-image-item .image {
  padding: 2rem 0;
  margin: 0;
  height: 60px;
}
.tile-image-item img {
  height: 60px;
  width: auto;
}
.tile-image-item .text {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  text-align: left;
}
@media (max-width: 599px) {
  .tile-image-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}
