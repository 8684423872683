.button-link {
  font-family: Roboto, sans;
  font-size: 1rem;
  background-color: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  padding: 0.5rem 1rem;
  margin: 2rem auto 0 auto;
  transition: 0.5s color, 0.5s background-color;
  border: 1px solid rgb(100, 100, 100);
  text-align: center;
  width: 100px;
}

.button-link:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition: 0.5s color, 0.5s background-color;
  cursor: pointer;
}
