.blog-paper {
  padding: 1rem;
}
.blog-image-block {
  text-align: center;
}
.blog-image-block img {
  width: 200px;
  height: auto; /* 266px; */
}
.blog-h3 {
  font-family: Roboto, sans;
  font-size: 1.1rem;
  font-weight: 700;
  color: rgb(80, 80, 80);
  padding: 0;
  margin: "1rem 0";
}
.blog-paper p {
  font-family: Roboto, sans;
  font-weight: 400;
  color: rgb(100, 100, 100);
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.9rem;
}
