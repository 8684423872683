.grid1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "image text";
  align-items: stretch;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text image";
  align-items: stretch;
}

.grid-text {
  grid-area: text;
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(110, 110, 110);
  text-align: center;
  margin: 0;
  padding: 3rem;
  padding-top: 5rem;
}

.grid-image {
  grid-area: image;
  background: rgb(220, 220, 220);
}

.box-image {
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

.svg-button {
  border: 1px solid rgb(100, 100, 100);
  background: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  padding: 0.5rem 1rem;
  width: max-content;
  margin: 2rem auto;
  font-size: 1rem;
  font-weight: 400;
  filter: invert(0%);
  text-align: center;
  transition: filter 0.5s;
}

.svg-button:hover {
  color: rgb(0, 0, 0);
  filter: invert(100%);
  transition: filter 0.5s;
}
.image {
  max-height: 320px;
}

.box {
  box-sizing: border-box;
  padding: 2rem 0;
}

.button-with-form {
  margin: 0 auto;
  padding-top: 2rem;
  max-width: max-content;
}

.button {
  border: 1px solid rgb(100, 100, 100);
  background-color: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  padding: 0.5rem 1rem;
  width: max-content;
  margin: 2rem auto;
  font-size: 1rem;
  font-weight: 400px;
  text-align: center;
  transition: color 0.5s, background-color 0.5s;
}

.button:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  transition: color 0.5s, background-color 0.5s;
  cursor: pointer;
}

.spoler-opened {
  max-height: 8000px;
  transition: max-height 0.5s;
  box-sizing: border-box;
}

.spoler-rolled {
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  box-sizing: border-box;
}

.graduates-image {
  margin: 0 auto;
  max-width: 320px;
}

.graduates-image img {
  max-width: 320px;
  height: auto;
}

.person-text a:link,
.person-text a:visited,
.person-text a:hover {
  text-decoration: none;
}

@media (max-width: 959px) {
  .grid1,
  .grid2 {
    grid-template-areas: "image" "text";
    grid-template-columns: 1fr;
  }

  .image {
    max-width: 90%;
  }

  .graduates-image {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .graduates-image img {
    max-width: 80vw;
    height: auto;
  }
}
