.image_in_slider {
  margin: 0 auto;
  align-self: center;
  transition: opacity 0.2s;
}
.slider__box {
  max-width: 340px;
}
.slider__for__dates__block {
  max-width: 340px;
}
.slider__box .window-image {
  overflow: hidden;
  width: 400px;
}
.slider__box .window-image .image-cnt {
  background-color: rgb(60, 60, 60);
  background-position: center;
  background-size: 200%;
  background-blend-mode: multiply;
  min-width: 400px;
  height: 400px;
  text-align: center;
  display: flex;
}
.image-cnt img.v {
  height: 400px;
  width: auto;
  margin-top: 0;
}
.image-cnt img.h {
  height: auto;
  width: 400px;
}

.slider__box-full .window-image {
  overflow: hidden;
  width: 500px;
}
.slider__box-full .window-image .image-cnt {
  background-color: rgb(60, 60, 60);
  background-position: center;
  background-size: 200%;
  background-blend-mode: multiply;
  min-width: 500px;
  height: 500px;
  text-align: center;
  display: flex;
}
.image-cnt.white-bkg {
  background-color: white;
  background-image: none;
  background-blend-mode: unset;
}
.slider__box-full .image-cnt img.v {
  height: 500px;
  width: auto;
  margin-top: 0;
}
.slider__box-full .image-cnt img.h {
  height: auto;
  width: 500px;
}
.slider__box,
.slider__box-full {
  max-width: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.slider__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.informer__control {
  display: inline-block;
  margin: 0 1rem;
}

.button__control:hover {
  cursor: pointer;
  filter: contrast(250%);
}

@media (max-width: 959px) {
  .image_in_slider {
    margin: 0 auto;
    max-width: 87vw;
  }
  .slider__for__dates__block {
    max-width: 87vw;
  }
  .slider__box .window-image,
  .slider__box-full .window-image {
    width: 80vw;
  }
  .slider__box .window-image .image-cnt,
  .slider__box-full .window-image .image-cnt {
    background-color: rgb(60, 60, 60);
    background-position: center;
    background-size: 200%;
    min-width: 80vw;
    height: 80vw;
    text-align: center;
  }
  .image-cnt img.v,
  .slider__box-full .image-cnt img.v {
    height: 80vw;
    width: auto;
  }
  .image-cnt img.h,
  .slider__box-full .image-cnt img.h {
    height: auto;
    width: 80vw;
  }
}
