.price h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  margin: 2rem auto;
  color: rgb(122, 101, 89);
  text-align: center;
}

.price h2 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 1rem auto;
  color: rgb(80, 80, 80);
  text-align: left;
}

.price p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: rgb(100, 100, 100);
  text-align: left;
}

.price div.course-box {
  padding-top: 3rem;
  max-width: 60%;
}

.price div.right {
  padding-left: 50%;
}

.price p.comment {
  font-size: 0.9rem;
}

@media (max-width: 959px) {
  .price div.right {
    padding-left: 0;
  }
  .price div.course-box {
    max-width: 100%;
  }
}
