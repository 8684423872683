.head-container h1 {
  font-family: "PT Sans Narrow", sans;
  font-size: 2.5rem;
  font-weight: 400;
  color: rgb(122, 101, 89);
  padding: 3rem 0;
  margin: 0;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
}

.head-container p {
  font-family: Roboto, sans;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  padding: 0;
  margin: 0;
  line-height: 1.6rem;
}
