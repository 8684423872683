.twoblock-container-left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto max-content;
  grid-template-areas:
    "head video"
    "descript video";
  grid-gap: 2rem;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 40%,
    rgb(230, 230, 230) 40%,
    rgb(230, 230, 230) 100%
  );
}
.twoblock-container-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto max-content;
  grid-template-areas:
    "video head"
    "video descript";
  grid-gap: 2rem;
  background: linear-gradient(
    to right,
    rgb(230, 230, 230) 0%,
    rgb(230, 230, 230) 60%,
    rgb(255, 255, 255) 60%,
    rgb(255, 255, 255) 100%
  );
}
.twoblock-head {
  grid-area: head;
  padding: 1rem 0;
}
.twoblock-video {
  grid-area: video;
  padding: 1rem;
  align-self: center;
}
.twoblock-descript {
  grid-area: descript;
  padding: 1rem 0;
}

@media (max-width: 959px) {
  .twoblock-container-left {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content;
    grid-template-areas:
      "head"
      "video"
      "descript";
    background: rgb(255, 255, 255);
  }
  .twoblock-container-right {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content;
    grid-template-areas:
      "head"
      "video"
      "descript";
    background: rgb(255, 255, 255);
  }
  .twoblock-video {
    text-align: center;
  }
}
