.t-i-cnt {
  display: grid;
  grid-template-areas: "image text";
  grid-template-columns: 2fr 5fr;
  align-items: center;
  grid-gap: 4rem;
}
.t-i-cnt.mb {
  margin-bottom: 4rem;
}
.t-i-cnt .text {
  grid-area: text;
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  line-height: 1.4rem;
  text-align: justify;
}

.t-i-cnt .image {
  grid-area: image;
  text-align: center;
  /* padding: 1rem; */
  height: 188px;
  display: grid;
}

.t-i-cnt .image img {
  margin: auto;
}

img.image-for-shess {
  width: 320px;
  height: auto;
}

@media (max-width: 1024px) {
  .t-i-cnt {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 599px) {
  .t-i-cnt {
    grid-template-areas: "image" "text";
    grid-template-columns: 100vw;
  }
  .t-i-cnt .image {
    text-align: center;
  }
  .t-i-cnt .text {
    text-align: left;
  }
  img.image-for-shess {
    width: 75vw;
    height: auto;
  }
}
