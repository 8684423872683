.button-white-in-black {
  font-size: 1rem;
  font-family: Roboto;
  border: 1px solid rgb(100, 100, 100);
  padding: 0.7rem 1rem;
  color: rgb(100, 100, 100);
  transition: 0.5s background, 0.5s color;
  background: rgb(255, 255, 255);
  max-width: max-content;
  cursor: pointer;
  text-align: center;
}
.button-white-in-black:hover {
  color: rgb(255, 255, 255);
  transition: 0.5s background, 0.5s color;
  background: rgb(0, 0, 0);
}
.image_in_slider {
  margin: 0 auto;
  transition: opacity 0.2s;
}

.slider__box {
  max-width: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.slider__text {
  font-family: Roboto;
  color: rgb(80, 80, 80);
  font-size: 1rem;
  max-width: 280px;
}

.slider__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.informer__control {
  display: inline-block;
  margin: 0 1rem;
}

.button__control:hover {
  cursor: pointer;
  filter: contrast(250%);
}

@media (max-width: 959px) {
  .button-white-in-black {
    max-width: 100%;
  }
  .image_in_slider {
    margin: 0 auto;
    max-width: 100%;
  }
}
