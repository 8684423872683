.contacts-cnt {
  display: grid;
  grid-template-areas:
    "name contact1 contact2 contact3"
    /*"name contact3 contact4"*/;
  max-width: max-content;
  grid-gap: 2rem;
  padding-top: 2rem;
  margin: 0 auto;
}

.contacts-cnt.inside {
  grid-template-areas: "name" "contact1" "contact2" "contact3";
  max-width: 100%;
  grid-gap: 0.5rem;
}

.contacts-cnt .nm {
  grid-area: name;
}

.contacts-cnt .c1,
.contacts-cnt .c2,
.contacts-cnt .c3 {
  line-height: 1rem;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
}

.contacts-cnt .c1 {
  grid-area: contact1;
}

.contacts-cnt .c2 {
  grid-area: contact2;
}

.contacts-cnt .c3 {
  grid-area: contact3;
}

.string-contact-p {
  color: rgb(100, 100, 100);
  font-family: Roboto;
  font-size: 0.9rem;
  font-weight: 400;
  /* margin-top: 1rem; */
  padding-left: 1rem;
}

a.string-contact-a:visited,
a.string-contact-a:link {
  color: rgb(60, 60, 120);
  text-decoration: none;
  font-size: 0.9rem;
}

a.string-contact-a:hover {
  color: rgb(50, 50, 50);
  text-decoration: none;
}

.string-contact-icon {
  fill: rgb(100, 100, 100);
}

.string-contact-icon:hover {
  fill: rgb(50, 50, 50);
}

.string-contact-icon-box {
  margin-bottom: -4px;
}

@media (max-width: 959px) {
  .contacts-cnt {
    grid-template-areas: "name" "contact1" "contact2" "contact3" /* "contact4" */;
  }
  .string-contact-p.nm {
    padding-left: 0;
  }
}
