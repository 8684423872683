.h1-style {
  font-family: "PT Sans Narrow";
  font-size: 3.2rem;
  font-weight: 500;
  color: rgb(122, 101, 89);
  text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 0;
  line-height: 3rem;
}
.m-t-b {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.m-t-b-double {
  margin-top: 4rem;
  margin-bottom: 6rem;
}
.center {
  text-align: center;
}
.small {
  font-family: Roboto;
  font-size: 0.8rem;
  color: rgb(100, 100, 100);
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.h2 {
  font-size: 1.6em;
  font-weight: 700;
  color: rgb(122, 101, 89);
  text-align: center;
  margin: 0;
  padding: 3.2rem 1rem 1.4rem;
}
.p {
  font-family: Roboto;
  font-size: 1rem;
  text-align: justify;
  color: rgb(100, 100, 100);
  line-height: 1.4rem;
  margin: 0;
  padding: 1rem 0;
}
.imageHead {
  margin: 0;
  padding: 3rem 1rem;
}
.imageHeadPicture {
  max-width: 100%;
  vertical-align: middle;
}
.box {
  max-width: 100vw;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.programma {
  border-top: 1px solid rgb(240, 240, 240);
}
.imageDate {
  margin: 0.25rem;
  max-width: 500px;
}
.topLink {
  font-size: 0.85rem;
  color: rgb(180, 180, 180);
  border-bottom: none;
  transition: 0.5s color;
}
.topLink:hover {
  border-bottom: 1px solid rgb(180, 180, 180);
  color: rgb(240, 240, 240);
}
.topBox {
  max-width: 1000px;
  margin: 0 auto;
  padding: 4px 10px 8px 10px;
  height: 35px;
  background: rgb(0, 0, 0);
  box-sizing: border-box;
}
.topLine {
  background: rgb(0, 0, 0);
  position: sticky;
  top: 0;
  height: 35px;
  width: 100%;
  padding: 0;
  z-index: 100;
}
.head {
  font-family: Alice;
  font-size: 3rem;
  color: rgb(255, 255, 255);
  text-align: left;
  line-height: 3rem;
}
.descript {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-align: left;
}
.footerTitle {
  font-size: 0.9rem;
  font-weight: 700;
  color: rgb(40, 40, 40);
  text-align: left;
}
.footerLink {
  font-size: 0.95rem;
  color: rgb(87, 123, 143);
  border-bottom: 1px solid rgb(87, 143, 143);
  transition: 0.5s color;
}
.footerLink:hover {
  border-bottom: 0;
  color: rgb(87, 143, 163);
}
.footerText {
  font-size: 1rem;
  font-weight: 400;
  color: rgb(70, 70, 70);
  text-align: left;
  line-height: 1.3rem;
  padding: 0;
  margin: 0;
}
.footerGold {
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: rgb(187, 144, 41);
  text-align: left;
  padding: 0;
  margin: 0;
}
.footer {
  background: rgb(255, 255, 255);
  padding: 4rem 0;
}
.nameSchool {
  text-align: left;
  font-family: Alice;
  font-size: 4rem;
  color: rgb(122, 101, 89);
  padding: 20vh 0 10vh 0;
  margin: 0;
}
.descriptSchool {
  text-align: left;
  font-size: 1rem;
  line-height: 1.6rem;
  color: rgb(100, 100, 100);
  padding: 0;
  padding-top: 3rem;
  padding-bottom: 1rem;
  margin: 0;
}
.login {
  padding: 0.2rem 0;
  text-align: right;
}
.scroll {
  font-size: 0.85rem;
  color: rgb(100, 100, 100);
  text-align: left;
  padding: 0;
  padding-bottom: 1.6rem;
}
.icons {
  text-align: right;
  padding: 0;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.geo {
  display: inline-block;
  width: 120px;
  padding: 0;
  padding-right: 1rem;
  margin: 0;
  vertical-align: top;
}
.adress {
  display: inline-block;
  width: 200px;
  padding: 0;
  padding-right: 1rem;
  margin: 0;
  vertical-align: top;
}
.time {
  display: inline-block;
  width: 230px;
  padding: 0;
  padding-right: 1rem;
  margin: 0;
  vertical-align: top;
}
.contacts {
  display: inline-block;
  width: 130px;
  padding: 0;
  padding-right: 1rem;
  margin: 0;
  vertical-align: top;
}
.services {
  display: inline-block;
  width: 240px;
  padding: 0;
  padding-right: 1rem;
  margin: 0;
  vertical-align: top;
}
.page {
  padding: 0;
}
.button-white-black {
  background: rgb(255, 255, 255);
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  transition: 0.5s color, 0.5s background;
  cursor: pointer;
  padding: 0.6rem 1rem;
  border: 1px solid rgb(100, 100, 100);
}
.button-white-black:hover {
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition: 0.5s color, 0.5s background;
  border: 1px solid rgb(0, 0, 0);
}
@media (max-width: 959px) {
  .geo {
    max-width: 100%;
    padding: 1rem;
  }
  .page {
    padding: 0 0.5rem;
  }
  .adress {
    max-width: 100%;
    padding: 1rem;
  }
  .time {
    max-width: 100%;
    padding: 1rem;
  }
  .contacts {
    max-width: 100%;
    padding: 1rem;
  }
  .services {
    max-width: 100%;
    padding: 1rem;
  }
  .nameSchool {
    padding: 2rem;
    font-size: 3.5rem;
  }
  .descriptSchool {
    padding-left: 2rem;
  }
  .scroll {
    padding-left: 1rem;
  }
  .icons {
    padding-right: 1rem;
  }
  .footer {
    padding: 4rem 1rem;
  }
  .imageHead {
    padding: 1rem 1rem;
  }
  .imageHeadPicture {
    width: 280px;
  }
  .box {
    padding: 0 1rem;
  }
  .h2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .programma {
    border-top: none;
  }
  .imageDate {
    margin: 0;
    width: 280px;
  }
  .h1-style {
    font-size: 2rem;
  }
}
