.shess-blocks-container {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
.item-shess {
  /* width: 60%; */
  margin-bottom: 1rem;
}
.item-shess h3 {
  font-family: "PT Sans Narrow", sans;
  font-size: 1.6rem;
  font-weight: 700;
  color: rgb(122, 101, 89);
  padding: 0 0 0.2rem;
  margin: 0 0 1rem;
  line-height: 1.2rem;
}
.item-shess p {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  padding: 0;
  margin: 0;
  line-height: 1.4rem;
}
.item-shess a:link,
.item-shess a:visited {
  color: rgb(142, 101, 89);
}
.item-shess a:hover {
  color: rgb(160, 0, 0);
}
.home-news-item {
  display: grid;
  grid-template-areas: "image text";
  grid-template-columns: 260px 1fr;
  margin-bottom: 3rem;
}
.home-news-image {
  grid-area: image;
  text-align: left;
}
.home-news-image img {
  width: 240px;
  height: 180px;
}
.home-news-text {
  grid-area: text;
}
.home-news-text p {
  grid-area: text;
  font-family: Roboto, sans;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  margin: 0 0 0.5rem 0;
  padding: 0;
  line-height: 1.3rem;
}
.home-news-text h3 {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(80, 80, 80);
  margin: 0 0 1rem 0;
  padding: 0;
  line-height: 1.5rem;
}
.home-news-container {
  padding: 4rem 0;
}
.home-news-container h2 {
  font-family: PT Sans Narrow, sans;
  font-size: 2.5rem;
  font-weight: 500;
  color: rgb(50, 50, 50);
  margin: 0;
  padding: 0 0 3rem 0;
  text-align: center;
  line-height: 2rem;
}
@media (max-width: 599px) {
  .shess-blocks-container {
    padding: 0 1rem;
    grid-template-columns: 1fr;
  }
  .item-shess {
    margin: 0 auto;
  }
  .home-news-item {
    grid-template-areas: "image" "text";
    grid-template-columns: 1fr;
  }
  .home-news-image {
    text-align: center;
  }
  .home-news-image img {
    width: 320px;
    height: 240px;
  }
  .home-news-container {
    padding: 4rem 1rem;
  }
}
