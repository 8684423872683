.constructor-cnt {
  padding-bottom: 2rem;
}

.h1-constructor {
  font-family: "PT Sans Narrow";
  font-size: 4rem;
  font-weight: 400;
  color: rgb(122, 101, 89);
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 0;
  margin: 1rem 0;
  line-height: 4rem;
}

.h1-constructor-header {
  font-family: "PT Sans Narrow";
  font-size: 2.5rem;
  font-weight: 400;
  color: rgb(122, 101, 89);
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 0;
  margin: 1rem 0;
  line-height: 2.5rem;
}

.small-constructor {
  font-family: Roboto;
  font-size: 0.8rem;
  color: rgb(100, 100, 100);
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.h2-constructor {
  font-size: 2em;
  font-weight: 700;
  color: rgb(122, 101, 89);
  text-align: center;
  margin: 0;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.h3-constructor {
  font-size: 1.4em;
  font-weight: 700;
  color: rgb(80, 80, 80);
  text-align: center;
  margin: 0;
  padding: 1rem;
}

.h4-constructor {
  font-size: 1rem;
  font-weight: 700;
  font-family: Roboto;
  color: rgb(80, 80, 80);
  text-align: center;
}

.p-constructor {
  font-family: Roboto;
  font-size: 1rem;
  text-align: justify;
  color: rgb(100, 100, 100);
  line-height: 1.4rem;
  font-weight: 400;
  margin: 0;
  padding: 1rem 0;
  box-sizing: border-box;
}

.t-small {
  font-size: 0.8rem;
}

.bold {
  font-weight: 700;
}

li.p-constructor {
  font-family: Roboto;
  font-size: 1rem;
  text-align: justify;
  color: rgb(100, 100, 100);
  line-height: 1.4rem;
  font-weight: 400;
  margin: 0;
  padding: 0.25rem 0;
  box-sizing: border-box;
}

.list-head-constructor {
  font-family: "PT Sans Narrow";
  font-size: 1.1em;
  font-weight: 700;
  color: rgb(80, 80, 80);
  text-align: left;
  margin: 1rem 0;
}

.list-constructor {
  font-family: Roboto;
  font-size: 1rem;
  text-align: left;
  color: rgb(100, 100, 100);
  line-height: 1.4rem;
  font-weight: 400;
  padding: 0;
  padding-bottom: 0.5rem;
}

.no-padding {
  padding: 0;
}

.p-b {
  padding-bottom: 3rem;
}

.text-center {
  text-align: center;
}

.block-center {
  margin: 0 auto;
}

.paper-modal-constructor {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 1rem 1rem 2rem;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.title-constructor {
  font-size: 1.2em;
  font-weight: 700;
  color: rgb(80, 80, 80);
  text-align: center;
  margin: 0;
}

.text-bold-box {
  margin: 6rem auto;
  max-width: 700px;
}

.text-bold {
  font-weight: 900;
  font-size: 3rem;
  text-align: center;
  color: rgb(100, 100, 100);
  margin: 1rem 0;
}

.text-bold-normal {
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  color: rgb(100, 100, 100);
  margin: 1rem 0;
}

.image-head-constructor {
  margin: 0;
  padding: 3rem 1rem;
}

.image-head-background {
  background-color: rgb(100, 100, 100);
  background-size: 200%;
  background-position: center;
  background-blend-mode: multiply;
  padding-top: 0;
  padding-bottom: 0;
}

.image-head-picture-constructor {
  max-width: 100%;
  vertical-align: middle;
}

.box-constructor {
  max-width: 100vw;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.programma-constructor {
  border-top: 1px solid rgb(240, 240, 240);
}

.image-date-constructor {
  margin: 0.25rem;
  max-width: 500px;
}

.space-quarter {
  margin-top: 2rem;
}

.space-half {
  margin-top: 4rem;
}

.space-constructor {
  margin-top: 8rem;
}

.card-image-img {
  max-width: 350px;
}

.card-image-container {
  max-width: 100%;
}

.card-image-picture-box {
  text-align: center;
}

.card-image-title {
  color: rgb(80, 80, 80);
  text-align: center;
  font-family: Roboto;
  font-weight: 900;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  max-width: 450px;
}

.card-image-text-box {
  padding-left: 2rem;
}

.card-image-text-title {
  font-family: Roboto;
  font-size: 1.2rem;
  color: rgb(80, 80, 80);
  font-weight: 900;
  text-align: center;
  margin-bottom: 1rem;
}

.card-image-text-diplom {
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(140, 140, 140);
  font-weight: 900;
  text-align: center;
  margin-bottom: 1rem;
}

.card-image-text-duration {
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(140, 140, 140);
  font-weight: 400;
  text-align: center;
  margin-bottom: 1rem;
}

.card-image-text-p {
  font-family: Roboto;
  font-size: 1rem;
  color: rgb(80, 80, 80);
  font-weight: 400;
  text-align: left;
  line-height: 1.9rem;
}

.digit-background-container {
  position: relative;
}

.digit-background-digit {
  position: relative;
  z-index: 10;
  font-family: sans-serif;
  font-size: 10rem;
  font-weight: 700;
  color: rgba(122, 101, 89, 0.25);
}

.digit-background-text {
  position: relative;
  top: -5rem;
  left: 2rem;
  z-index: 20;
  text-align: left;
  font-family: Roboto;
  font-size: 1.5rem;
  color: rgb(100, 100, 100);
  line-height: 2rem;
  max-width: calc(100% - 4rem);
}

.block-left-space {
  margin-left: 4rem;
}

.button-constructor {
  border: 1px solid rgb(100, 100, 100);
  background: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  padding: 0.7rem 1rem;
  max-width: max-content;
  transition: 0.5s color, 0.5s background;
  text-align: center;
  cursor: pointer;
}

.button-constructor:hover {
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
}

.pay-element-box {
  margin: 2rem auto;
}

.video-element-vertical {
  width: 100%;
  /* max-height: 560px; */
}

.video-local-in-line {
  width: 90%;
  margin: 0 auto;
}

.message {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.message-head {
  font-family: "Roboto";
  font-size: 1.2rem;
  font-weight: 700;
  color: rgb(80, 80, 80);
  line-height: 2rem;
  margin-bottom: 2rem;
}

.message-text {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
  text-align: justify;
  line-height: 1.4rem;
}

.space-head {
  height: 2rem;
}

.h1-constructor-empty {
  height: 0;
}

.forma-on-head {
  padding: 1rem;
  box-shadow: 1px 1px 5px 5px rgba(100, 100, 100, 0.1);
}

a.link-constructor:link,
a.link-constructor:visited {
  color: rgb(123, 143, 87);
  text-decoration: none;
}

a.link-constructor:hover {
  color: rgb(100, 120, 67);
  text-decoration: none;
}

.message-on-paper {
  max-width: 800px;
  margin: 0 auto;
  background-color: rgb(220, 220, 220);
  padding: 1rem 2rem 1.5rem;
  box-sizing: border-box;
}

.whatsapp-telegram {
  display: grid;
  grid-template-areas: "whatsapp telegram";
  grid-gap: 0;
  max-width: 600px;
  margin: 0 auto;
}

.whatsapp-telegram .whatsapp,
.whatsapp-telegram .telegram {
  grid-area: whatsapp;
  text-align: center;
  padding: 0.5rem 0;
  box-sizing: border-box;
}

.whatsapp-telegram .whatsapp {
  background-color: rgb(0, 230, 118);
}

.whatsapp-telegram .telegram {
  background-color: rgb(40, 168, 233);
}

.whatsapp p,
.telegram p {
  font-family: Roboto, sans;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  padding: 0;
  margin: 0;
}

.whatsapp img {
  width: 36px;
  height: 36px;
}

.telegram img {
  width: auto;
  height: 36px;
}

.text-3-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.text-3-block-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 2rem;
  max-width: 700px;
  margin: 0 auto;
}

.text-3-block > div h6,
.text-3-block-2 > div h6 {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: rgb(80, 80, 80);
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
  line-height: 1.4rem;
}

.text-3-block > div p {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: rgb(100, 100, 100);
  line-height: 1.8rem;
  padding: 0;
  margin: 0;
}

.text-3-block > div img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.uny-btn {
  margin: 2rem auto;
  max-width: max-content;
  background-color: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  border-style: solid;
  border-color: rgb(100, 100, 100);
  border-width: 1px;
  padding: 1rem;
  font-weight: 400;
  font-family: Roboto, sans;
  font-size: 1rem;
  text-align: center;
  box-sizing: border-box;
  transition: color 0.5s, background-color 0.5s;
}

.uny-btn:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-color: rgb(0, 0, 0);
  transition: color 0.5s, background-color 0.5s;
}

.image-bkg {
  background-color: rgb(100, 100, 100);
  background-blend-mode: multiply;
  background-position: center;
  background-size: 200%;
  width: 320px;
  overflow: hidden;
  height: 240px;
  text-align: center;
}

.image-bkg img.h {
  width: 320px;
  height: auto;
}

.image-bkg img.v {
  width: auto;
  height: 240px;
}

img.diplom {
  max-width: 100%;
}

.diplom-container {
  max-width: 1000px;
  box-sizing: border-box;
}

@media (max-width: 959px) {
  .video-element-vertical {
    width: 100%;
    height: auto;
  }

  .video-local-in-line {
    width: 100%;
  }

  .forma-on-head {
    padding-bottom: 1rem;
    margin-top: 1.5rem;
  }

  .text-3-block {
    grid-template-columns: calc(100vw - 2rem);
    grid-template-rows: 1fr 1fr 1fr;
    max-width: calc(100vw - 2rem);
    justify-content: center;
    row-gap: 4rem;
  }

  .text-3-block > div p,
  .text-3-block > div h6 {
    text-align: center;
  }

  .text-3-block > div img {
    width: calc(100vw - 2rem);
    height: auto;
  }

  .space-head {
    height: 0;
  }

  .h1-constructor-empty {
    height: 0;
  }

  .p-constructor {
    text-align: left;
  }

  .message {
    max-width: 80vw;
  }

  .imag-head-constructor {
    padding: 1rem 1rem;
  }

  .image-head-picture-constructor {
    width: 280px;
  }

  .box-constructor {
    padding: 0 1rem;
  }

  .h1-constructor {
    margin-top: 0;
  }

  .h2-constructor {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .programma-constructor {
    border-top: none;
  }

  .image-date-constructor {
    margin: 0;
    width: 280px;
  }

  .paper-modal-constructor {
    max-width: 70%;
    margin: 2rem;
  }

  .block-left-space {
    margin-left: 0;
  }

  .text-bold-box {
    margin: 3rem 0;
    padding: 1rem;
    max-width: 100%;
  }

  .margin-bot-mobile {
    margin-bottom: 1rem;
  }

  .button-constructor {
    max-width: 100%;
  }

  .card-image-img {
    max-width: 70vw;
  }

  .card-image-text-box {
    padding-left: 0rem;
  }

  .message-on-paper {
    max-width: calc(100vw - 2rem);
  }

  .whatsapp-telegram {
    max-width: 100vw;
  }

  .uny-btn {
    max-width: 100vw;
  }

  .image-bkg {
    width: 75vw;
    height: calc(75vw / 1.333);
  }

  .image-bkg img.h {
    width: 75vw;
    height: auto;
  }

  .image-bkg img.v {
    width: auto;
    height: calc(75vw / 1.333);
  }

  .papers {
    padding: 1rem;
  }

  .diplom-container {
    max-width: 100vw;
  }

  img.diplom {
    max-width: 95vw;
  }
}
