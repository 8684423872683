.g4x-tile-container {
  width: 320px;
  margin-bottom: 3rem;
}
.g4x-tile-container img {
  width: 320px;
  height: 240px;
}
.g4x-date {
  text-align: center;
  font-family: Roboto, sans;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  margin: 1rem 0;
}
.g4x-title-link {
  text-align: center;
  font-family: Roboto, sans;
  font-weight: 700;
  color: rgb(80, 80, 80);
  margin: 0;
}
.g4x-gray-text {
  text-align: left;
  font-family: Roboto, sans;
  font-size: 0.9rem;
  color: rgb(120, 120, 120);
  margin: 1rem 0 0 0;
}
