h2.t-sh-mob {
  display: none;
}

.t-block-block {
  display: grid;
  grid-template-areas: "left right";
  grid-gap: 1rem;
  align-items: stretch;
}

.t-block-block .t-left-bb {
  grid-area: left;
}

.t-block-block .t-right-bb {
  grid-area: right;
}

.t-disk {
  border-radius: 50%;
  background-color: rgb(255, 220, 0);
  align-self: flex-start;
  max-width: 144px;
  max-height: 144px;
  width: 144px;
  height: 144px;
}

.t-disk .t-discount {
  font-family: Roboto, sans;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: rgb(50, 0, 0);
  margin: 0;
  padding: 0;
  margin-top: 54px;
  line-height: 1rem;
}

.t-disk .t-duration {
  font-family: Roboto, sans;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: rgb(50, 0, 0);
}
.t-price h2 {
  font-family: "PT Sans Narrow", sans;
  color: rgb(122, 101, 89);
  font-weight: 700;
  font-size: 1.6rem;
  padding: 1rem 0 1.4rem;
  display: block;
}

.t-pr-els .t-discount,
.t-pr-els .t-full,
.t-pr-els .t-installment {
  font-family: Roboto, sans;
  font-weight: 400;
  text-align: left;
}

.t-pr-els .t-discount-pr,
.t-pr-els .t-full-pr,
.t-pr-els .t-installment-pr {
  font-family: Roboto, sans;
  font-weight: 700;
  text-align: left;
}

.t-pr-els .t-discount {
  font-size: 1rem;
  color: rgb(100, 100, 100);
}

.t-pr-els .t-discount-pr {
  font-size: 2rem;
  color: rgb(100, 100, 100);
}

.t-pr-els .t-installment {
  font-size: 1.2rem;
  color: rgb(80, 80, 80);
}

.t-pr-els .t-installment-pr {
  font-size: 3rem;
  color: rgb(80, 80, 80);
}

.t-installment p {
  font-family: Roboto, sans;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: right;
  color: rgb(100, 100, 100);
  /* line-height: 1.2; */
  margin: 0.5rem 0;
  padding: 0;
}

.t-pr-els .t-full {
  font-size: 1rem;
  color: rgb(160, 160, 160);
}

.t-pr-els .t-full-pr {
  font-size: 2rem;
  color: rgb(160, 160, 160);
  text-decoration: line-through;
  text-decoration-style: solid;
}

.t-pr-text {
  font-size: 1rem;
  font-weight: 400;
}

.t-pr-cont {
  display: grid;
  grid-template-areas: "left medial right" "button1 medial button2";
  grid-template-columns: 3fr 1fr 3fr;
  grid-gap: 2rem;
}

.t-pr-cont-nButton {
  display: grid;
  grid-template-areas: "left medial right";
  grid-template-columns: 3fr 1fr 3fr;
  grid-gap: 2rem;
}

.t-button1 {
  grid-area: button1;
}

.t-button2 {
  grid-area: button2;
}

.t-button2 .button {
  margin: 0 auto;
  width: 198px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid rgb(100, 100, 100);
  padding: 0.5rem 1rem;
  font-family: Roboto, sans;
  font-size: 1rem;
  background-color: rgb(255, 200, 0);
  color: rgb(100, 0, 0);
  transition: 0.5 color, 0.5 background-color;
}

.t-button2 .button:hover {
  background-color: rgb(100, 0, 0);
  color: rgb(255, 255, 255);
  transition: 0.5 color, 0.5 background-color;
  cursor: pointer;
}

.t-button1 .button {
  margin: 0 auto;
  width: 198px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid rgb(100, 100, 100);
  padding: 0.5rem 1rem;
  font-family: Roboto, sans;
  font-size: 1rem;
  background-color: rgb(255, 255, 255);
  color: rgb(100, 100, 100);
  transition: 0.5 color, 0.5 background-color;
}

.t-button1 .button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition: 0.5 color, 0.5 background-color;
  cursor: pointer;
}

.t-pr-cont .t-left {
  grid-area: left;
  align-self: stretch;
}

.t-pr-cont .t-medial,
.t-pr-cont-nButton .t-medial {
  grid-area: medial;
  display: flex;
}

.t-pr-cont .t-right {
  grid-area: right;
  padding-top: 0;
}

.t-installment .t-medium,
.t-installment .t-descript {
  font-family: Roboto, sans;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(100, 100, 100);
}

.t-installment .t-descript,
.t-left .t-descript {
  font-size: 0.8rem;
  margin-top: 2rem;
  color: rgb(100, 100, 100);
}

.t-descript.pt {
  padding-top: 0.8rem;
}

.t-installment h3 {
  font-family: Roboto, sans;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: right;
  color: rgb(80, 80, 80);
  margin-top: 1rem;
}

.t-btn-installment {
  margin: 0 auto;
  background-color: rgb(255, 220, 0);
  color: rgb(50, 0, 0);
  font-family: Roboto, sans;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid rgb(100, 100, 100);
  transition: color 0.5s, background-color 0.5s;
  text-align: center;
  width: 290px;
}

.t-btn-installment:hover {
  background-color: rgb(255, 128, 0);
  color: rgb(255, 255, 255);
  transition: color 0.5s, background-color 0.5s;
}

p.t-medium {
  font-family: Roboto, snas;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin: 0;
  color: rgb(100, 100, 100);
}

p.t-medium.c {
  text-align: center;
}

p.t-medium.pt {
  margin-top: 2rem;
}

.t-qr-code {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.t-qr-code img {
  width: 200px;
  height: 200px;
}

@media (max-width: 599px) {
  .t-block-block {
    grid-template-areas: "left" "right";
  }
  .t-pr-cont {
    grid-template-areas: "medial" "left" "button1" "right" "button2";
    grid-template-columns: 100vw;
  }
  .t-pr-cont .t-medial {
    max-width: max-content;
    margin: 0 auto;
  }
  .t-installment {
    max-width: 80vw;
    padding: 1rem;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .t-price h2 {
    display: none;
  }
  h2.t-sh-mob {
    font-family: "PT Sans Narrow", sans;
    color: rgb(122, 101, 89);
    font-weight: 700;
    font-size: 1.6rem;
    padding: 1rem 0 1.4rem;
    display: block;
  }
  .t-discount {
    max-width: 100vw;
    box-sizing: border-box;
  }
  .t-price {
    max-width: 90vw;
  }
  .t-right {
    box-sizing: border-box;
    max-width: 90vw;
  }
}
