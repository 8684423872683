.brand {
  font-weight: 700;
  color: rgb(122, 101, 89);
}

.quote-text {
  font-family: "Playfair Display", sans-serif;
  font-style: italic;
  font-size: 1rem;
  color: rgb(100, 100, 100);
  line-height: 1.5rem;
  text-align: left;
  padding-left: 2rem;
}
