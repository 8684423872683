.hash-text {
  color: rgb(143, 123, 87);
}

.hash-text:hover {
  color: rgb(43, 123, 87);
  cursor: pointer;
}

.hash-block {
  text-align: center;
  margin-bottom: 4rem;
}
